import { Hero, AgeDisc, GamesList, CTA,  Description, Benefits, NoMoney, CookieModal } from "./components";
import { ThemeProvider } from '@mui/material';
import theme from "./theme";
import DisclaimerModal from "./components/DisclaimerSection";

function App() {
  return (
    <div className="App">
<ThemeProvider theme={theme}>
      <Hero/>
      <Benefits/>
      <GamesList/>
      <DisclaimerModal/>
      <AgeDisc/>
      <NoMoney/>
      <CookieModal/>
      </ThemeProvider>
    </div>
  );
}

export default App;
