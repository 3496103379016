import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles'; 
import Grow from '@mui/material/Grow';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    color: "#ffffff"
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textAlign: 'center',
    color: "#ffffff"
  },
  paper: {
    padding: "2rem 1.5rem",
    borderRadius: '30px!important',
    flex: 1, 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#EDB426!important',
    backgroundColor: '#0a1f12!important'
  },
}));


export default function Benefits() {
  const classes = useStyles(); // Apply the defined styles

  return (
    <Container maxWidth="lg" className={classes.container} id="about">
      <Typography variant="h3" gutterBottom textAlign="center">
      About Us
      </Typography>
      <Typography variant="h5" gutterBottom textAlign="center">
  We started with a vision – to make unlimited joy accessible to everyone. echodblend was born out of the belief that games are a powerful medium to connect, inspire, and spread happiness. Our aim is steadfast: to create a place where the fun never stops, and everyone is welcome.
</Typography>
<Typography variant="h3" gutterBottom textAlign="center" marginTop="2rem">
  Experience the Unique Edge of Echodblend:
</Typography>
<Grid container justifyContent="center" alignItems="stretch" spacing={4} marginTop="1rem">
    <Grid item xs={12} sm={4} className={classes.paperContainer}>
      <Paper className={classes.paper}>
        <Typography variant="h5">
        Diversity
        </Typography>
        <Typography variant="body1">
        Dive into our extensive library of games across all genres, ensuring you never run out of options to match your current vibe.
        </Typography>
      </Paper>
    </Grid>

    <Grid item xs={12} sm={4} className={classes.paperContainer}>
      <Paper className={classes.paper}>
        <Typography variant="h5">
        Ease of Use:
        </Typography>
        <Typography variant="body1">
        Our platform is designed with simplicity in mind, making it easy for you to find and enjoy your favorite games hassle-free.
        </Typography>
      </Paper>
    </Grid>

    <Grid item xs={12} sm={4} className={classes.paperContainer}>
      <Paper className={classes.paper}>
        <Typography variant="h5">
        Cost-Free Fun
        </Typography>
        <Typography variant="body1">
        Enjoy our games for free, with no surprise charges or need for subscriptions. Just pure entertainment.
        </Typography>
      </Paper>
    </Grid>

    <Grid item sm={12} className={classes.paperContainer}>
      <Paper className={classes.paper}>
        <Typography variant="h5">
        Gaming Community
        </Typography>
        <Typography variant="body1">
        Become part of an enthusiastic gaming community, where you can share tips, experiences, and forge new friendships.
        </Typography>
      </Paper>
    </Grid>
</Grid>
    </Container>
  );
}
