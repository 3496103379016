import {BuffaloKing, VikingForge, 
  RiseOfSamurai, ElementalGems, CrystalCaverns, GreatRhino, MightyMunchingMelons, BigBass, CowboysGold
} from "../assets";

 const gamesData = [
  [
    {
      id: 1,
      title: 'Buffalo King',
      image: BuffaloKing,
      iframeUrl: 'https://cutt.ly/5wNwHGWI',
    },
    {
      id: 2,
      title: 'Viking Forge',
      image: VikingForge,
      iframeUrl: 'https://cutt.ly/cwNwvF8y',
    },
    {
        id: 3,
        title: 'Rise of Samurai',
        image: RiseOfSamurai,
        iframeUrl: 'https://cutt.ly/MwNwKeod',
      },
      {
        id: 4,
        title: 'Elemental Gems',
        image: ElementalGems,
        iframeUrl: 'https://cutt.ly/LwNwJDrj',
      },
      {
        id: 5,
        title: 'Crystal Caverns',
        image: CrystalCaverns,
        iframeUrl: 'https://cutt.ly/owNwGamR',
      },
      {
        id: 6,
        title: 'Great Rhino',
        image: GreatRhino,
        iframeUrl: 'https://cutt.ly/lwNwQny3',
      },
      {
        id: 7,
        title: 'Mighty Melons',
        image: MightyMunchingMelons,
        iframeUrl: 'https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20mmmelon&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobby_url=https%3A%2F%2Fwww.pragmaticplay.com%2Fen%2F&lang=EN&cur=USD',
      },
      {
        id: 8,
        title: 'Big Bass',
        image: BigBass,
        iframeUrl: 'https://cutt.ly/4wNwSurw',
      },
      {
        id: 9,
        title: 'Cowboys Gold',
        image: CowboysGold,
        iframeUrl: 'https://cutt.ly/1wNwDYIx',
      },],
     
  ];

  const games = gamesData[0];
  
  export default games;