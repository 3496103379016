import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Form from "./Form";
import Header from "./Header";
import { contacts } from "../assets";
import DisclaimerSection from "./DisclaimerSection";

export default function Contacts() {
  return (
    <div>
      <Header />
      <Container maxWidth="lg" id="contacts">
        <Typography variant="h3" textAlign="center" gutterBottom>
          Have Any Question?
        </Typography>
        <Grid
          container
          justifyContent="center"
          textAlign="center"
          alignItems="center"
          marginTop="2rem"
        >
          <Grid item xs={12} sm={6}>
            <Form />
          </Grid>
          <Grid item xs={12} sm={6} mx="auto">
          <Typography variant="h4" gutterBottom>
            Contact information:
              </Typography>
            <Typography variant="h5" gutterBottom>
            2 Valentine Pl, London <br/> SE1 8QH, United Kingdom
              </Typography>
              <Typography variant="h5" gutterBottom>
              +448451233773
              </Typography>
              <Typography variant="h5" gutterBottom>
              contact@echodblend.com
              </Typography>

          </Grid>
        </Grid>
      </Container>
      <DisclaimerSection />
    </div>
  );
}
