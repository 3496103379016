// src/components/Hero.js
import React from "react";
import Header from "./Header"; // Import your Header component
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { heroBg, bg } from "../assets";
import Grow from '@mui/material/Grow';

function Hero() {
  return (
    <Box
      height="80vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      style={{ 
        background: `linear-gradient(170deg, rgba(0,0,0,.4) 0%, rgba(0, 0, 0,.8) 70%), url(${bg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "bottom",
      }}
    >
    
        <Header />
        <Container maxWidth="lg">
        <Grow in={true} timeout={1200}>

          <Box px={3} textAlign="center">
            <Typography variant="h1" fontSize={{xs: "4rem", md: "6rem"}} color="secondary" gutterBottom style={{fontWeight: "900"}}>
            Dive into our world of gaming today

            </Typography>
            <Typography variant="h5" color="primary.main50" >
            Where fun meets infinite possibilities!   
            </Typography>
                   </Box>
          </Grow>

        </Container>
    </Box>
  );
}

export default Hero;
