import React from 'react';

export default function NoMoney() {
  return (
    <div className="no-money-disclaimer">
      <div className="nomoney-icon">
      <img src="https://img.icons8.com/?id=76MTnAV3b8gc&format=png" alt="" />
           </div>
      <div className="message">No Real Money. <br/> Free online games. 18+</div>
    </div>
  );
}
