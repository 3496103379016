import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {bg} from "../assets";
import {MdNoAdultContent} from "react-icons/md";

function Copyright() {
  return (
    <Typography variant="body2" >
      {'Copyright © '}
      echodblend {new Date().getFullYear()}{'.'}
    </Typography>
  );
}


export default function StickyFooter() {
  return (
      <Box
        component="footer"
        sx={{
          px: 2,
          mt: 'auto',           
          backgroundColor: "secondary", color: "#ffffff"
        }}
      >
        <Container maxWidth="sm" style={{paddingTop: "1.5rem", paddingBottom: "1.5rem", display: "flex", justifyContent: "center", alignItems: "end", textAlign: "center"}}>
          <div>
          <Typography variant="body1">
            <Link to="/terms"  style={{color: "#ffffff", textDecoration: "none"}}>
              Terms
            </Link>{' '}
            |{' '}
            <Link to="/privacy"  style={{color: "#ffffff", textDecoration: "none"}}>
              Privacy Policy
            </Link>
          </Typography>
          <Copyright />
          <Typography variant="body1">
            NO REAL MONEY. 18+
        </Typography>
          </div>
        </Container>
      </Box>
  );
}
